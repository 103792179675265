import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4><a parentName="h4" {...{
        "href": "/"
      }}>{`home`}</a>{` / `}<a parentName="h4" {...{
        "href": "/resume"
      }}>{`resume`}</a>{` / `}<a parentName="h4" {...{
        "href": "/projects"
      }}><em parentName="a">{`projects`}</em></a></h4>
    <hr></hr>
    <h1>{`>_ projects`}</h1>
    <p><em parentName="p">{`Compilation of a mixture of professional and recreational projects in the space of IT, Software Development, DevOps, and Infosec`}</em></p>
    <h3>{`List`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/samroy92/samroyio-website"
        }}>{`samroyio-website`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/samroy92/samroyio-infrastructure"
        }}>{`samroyio-infrastructure`}</a></li>
    </ul>
    <h3>{`DevOps`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-terraform"
      }}>{`terraform {
  required_providers {
    aws = {
      source = "hashicorp/aws"
      version = "4.11.0"
    }
    cloudflare = {
        source = "cloudflare/cloudflare"
        version = "~> 3.0"
    }
  }

  backend "s3" {
      bucket = "samroyio-website-backend"
      region = "us-east-1"
      key = "terraform.tfstate"
  }
}

provider "aws" {
  region = "us-east-1"
}
`}</code></pre>
    <h3>{`IT Automation`}</h3>
    <pre><code parentName="pre" {...{}}>{`//TODO
`}</code></pre>
    <h3>{`Software Development`}</h3>
    <pre><code parentName="pre" {...{}}>{`const Layout = ({ children }: Props) => (
  <StaticQuery
    query={graphql\`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    \`}
    render={(data) => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { name: 'description', content: 'Sample' },
            { name: 'keywords', content: 'sample, something' },
          ]}
        >
          <html lang="en" />
        </Helmet>
        <Header siteTitle={data.site.siteMetadata.title} />
        <Container style={{}}>{children}</Container>
      </>
    )}
  />
)
`}</code></pre>
    <h5><a parentName="h5" {...{
        "href": "/"
      }}>{`back to home`}</a>{` / `}<a parentName="h5" {...{
        "href": "#top"
      }}>{`top of page`}</a></h5>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      